import React from 'react'
import abt from '../../images/about.webp'
import img from '../../images/abt1.webp'
import { useMediaQuery } from 'react-responsive'
import { IoIosArrowRoundForward } from "react-icons/io";
import { setToggleTrue } from '../../redux/Slice/changeStates'
import { useDispatch, useSelector } from 'react-redux';
import Contact from './Contact';
import { NavLink } from 'react-router-dom';

const About = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 991px)' });
    const dispatch = useDispatch()
    const state = useSelector((state) => state);
    return (
        <>
        <div className="about-section" id="about-section">
            <div className="container">
               
                
                <div className='row'>
                    <div className='col-lg-6 col-md-12 d-flex align-items-center' style={{ borderRight: !isMobile ? '1px solid #CDD5A2' : "" }}>
                        <div className="about-card">
                            <div className="about-content position-relative">
                                <h1 className='text-uppercase'>Welcome to Your New Home: The Square</h1>
                                <p>Welcome to a luxury living experience at The Square, where every villa is designed for comfort, luxury, and exclusivity in the prime location of Adibatla.</p>
                                <div className='about-content-image'><img src={img} alt="" width="150"/></div>
                                <NavLink onClick={() => dispatch(setToggleTrue())} className="btn btn-enquiry">Schedule a Site Visit <IoIosArrowRoundForward size={30} /></NavLink>
                                <div className='position-relative' style={{padding: "0% 17% 0% 7%"}}>
                                    
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-12'>
                        <div className="about-image" >
                            <img src={abt} alt="about" className='img-fluid' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {
            state.toggle.hastoggle && <Contact />
        }
          </>
    )
}

export default About
